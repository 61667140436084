import React, {Component} from 'react';

import Header from '../shared/header';
import Footer from '../shared/footer';
import StaticBanner from '../shared/staticbanner';
import {Container, Row} from 'react-bootstrap';
import dateFormat from 'dateformat';

import data from '../data/eventsData.json'

class Events extends Component{
    constructor(props){
        super(props);   
        this.state = {
            Posts:[],
            isLoaded:false
        }
    }

    componentDidMount(){
        fetch('https://twodadsuk.com/blog/wp-json/tribe/events/v1/events')
        .then(result => result.json())
        .then( json => {
            this.setState({
                Posts:json,
                isLoaded:true
            })
        })
    }

    
render(){
    document.title ="Dates for your Calendar, Events - TwoDads U.K - Surrogacy Support in UK"; 
    document.getElementsByTagName("META")[2].content="Get your Calendar booked with the dates here. Dates for your Calendar. This will icnlude all the list of events we organize or conduct for raising awareness about Surrogacy program to support same sex parents to have their own family.";
    document.getElementsByTagName("META")[3].content="Dates for your Calendar, Events, TwoDads U.K, Surrogacy Support in UK, Two Dads in UK, Surrogacy in UK, Gay Parents with Surrogacy Support, Surrogacy Support to grow your family, Daddies change too, Gay parents, Gay Couple, Two Dads";

    document.querySelector("link[rel='canonical']").setAttribute("href","https://www.twodadsuk.com/events");
    
    const {isLoaded, Posts}=this.state;
 

  if(!isLoaded){
      return(<p style={{ textAlign: 'center', fontSize: '2em' }}>Hold on, fetching data may take some time :)</p>);
  }else{
    return(

        <div className="wrapper">
            <Header/>            
            {data.eventBanner.map((item, i)=>{
                    return <StaticBanner BannerCont={item} key={`Blogdata-${i}`}/>
                })
            }
            <div className="section event-sec">
                <Container>
                    {
                        data.EventData.map((dta,i)=>{
                        return <h2 className="main-tl text-uppercase">{dta.title}</h2>
                        })
                    }
                    <div className="data-list">
                        {
                            Posts.events.map((datadiary, i)=>{
                                return(
                                    <Row className="row-grid align-items-center">
                                        <div className="col-md-auto ml-md-auto"><a href={datadiary.website} target="_blank" rel="noopener noreferrer" ><img src={datadiary.image.sizes.medium.url} alt="" width="200px" height="auto" /></a></div>
                                        <div className="col-md-auto ml-md-auto"><a href={datadiary.website} target="_blank" rel="noopener noreferrer" className="date-sec btn btn-purple">{dateFormat(datadiary.start_date, "dS mmmm, yyyy")}</a></div>
                                        <div className="col-md"><b>{datadiary.title}</b><br/>Venue: {datadiary.venue.address}, {datadiary.venue.city}, {datadiary.venue.country} - {datadiary.venue.zip}</div>
                                        <div className="col-md-auto mr-md-auto"><a href={datadiary.website} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Register</a></div>
                                    </Row>

                                )
                            })
                        }
                    </div>
                </Container>
            </div>
            <Footer/>
        </div>
        )
    }
}
}
export default Events;