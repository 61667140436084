import React, {Component} from 'react';
import {Container} from 'react-bootstrap';

class StaticBanner extends Component{
    render(){
        const {BannerCont} = this.props;
        return(
            <div className="static-banner">
                <Container>
                    <h2 className="main-tl">{BannerCont.title}</h2>
                    <p>{BannerCont.para}</p>
                </Container>
            </div>
        )
    }
}

export default StaticBanner;