import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {Container, Row, Col} from 'react-bootstrap';
//import {Link} from 'react-router-dom';
import data from '../data/homeData.json';

function OurMission(){

    // setTimeout(function(){
    //     var OurMission = document.querySelector('.our-mission-sec');
    //     var OurMissionBox = document.querySelectorAll('.our-mission-sec .row .col');
    //     for(let i = 0; i < OurMissionBox.length; i++){
    //         //console.log(OurMissionBox[i]);
    //         if(i > 3){
    //             OurMissionBox[i].setAttribute('class',"col hidden toggled")

    //                 var bvTag = document.querySelector('.btn-view.more');
    //                 bvTag.setAttribute('href','javascript: void(0)');
    //                 bvTag.setAttribute('class',"btn btn-primary btn-view more");
    //         }
    //     }
        
    //     function ShowMoreList() {
    //         const t2 = OurMission.querySelector(".btn-view");
    //         if (t2.firstChild.nodeValue === "View More") {
    //             OurMission.querySelectorAll('.hidden').setAttribute('class',"col toggled")
    //             bvTag.innerText = "View Less";
    //             bvTag.setAttribute('class',"btn btn-primary btn-view less");
    //         } else if(t2.firstChild.nodeValue === "View Less") {
    //             OurMission.querySelectorAll('.toggled').setAttribute('class',"col hidden")
    //             bvTag.innerText = "View More";
    //             bvTag.setAttribute('class',"btn btn-primary btn-view more");
    //         }else{    
    //         }
    //     }
    //     const el = OurMission.querySelector(".btn-view");
    //     el.addEventListener("click", ShowMoreList);        
    // },800)

    return(
        <ScrollAnimation className="section our-mission-sec" animateIn='fadeInUp' animateOnce={true} delay={300}>
            <Container>
                {
                    data.OurMissionSec.map((item, i) => {
                        return(
                            <div key={`OurMissionSec-list-${i}`}>
                                <h2 className="main-tl">{item.title}</h2>
                                <p className="spl-txt"><strong>{item.SecTextHighlight}</strong></p>
                                <p className="spl-txt">{item.SecText}</p>
                                <Row xs={1} md={2} className="text-center">
                                    { item.OurMissionList.map((MissionLlist, i)=>{
                                        return(
                                            <Col key={`OurMission-list-${i}`}>
                                                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={400} className="Box">
                                                    <i className="icon"><img src={MissionLlist.ImgUrl} alt=""/></i>
                                                    <h3 className="tl">{MissionLlist.title}</h3>
                                                    <p>{MissionLlist.para}</p>
                                                </ScrollAnimation>
                                            </Col>
                                        )
                                    })
                                    }
                                </Row>
                                {/* <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={500}  className="text-center btn-container">
                                    <Link className="btn btn-primary btn-view more">View More</Link>
                                </ScrollAnimation> */}
                            </div>
                        )
                    })
                }
            </Container>
        </ScrollAnimation>
    )
}

export default OurMission;