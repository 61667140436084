import React from 'react';
import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap';


import { NavLink, Link } from 'react-router-dom'

window.addEventListener("scroll", function() {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    //var mobNavscrup = window.pageYOffset;

    if(window.outerWidth <= 991){
        //console.log('We are below 991');
        var navclick = document.querySelector('.navbar-toggler');
        if(!navclick.classList.contains('collapsed')){
            navclick.click();
        }
        //document.querySelector('.navbar-toggler').click();
    }else{

    }

  
    if (st === 0) {      
        document.getElementById("header").classList.remove("sticky");
    } else {
        document.getElementById("header").classList.add("sticky");
    }
  })

function Header(){
    return(
        <header id="header">
            <Navbar expand="lg">
                <Container>
                    <Navbar.Brand><Link to="/" className="logo"><img src="../Logo@4x.png" alt=""/></Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <NavLink activeclassname="active" className="nav-link" to="/" exact >Home</NavLink>
                            {/* <NavLink activeclassname="active" className="nav-link" to="/about">About Us</NavLink> */}
                            <NavDropdown title="About Us" id="basic-nav-dropdown">
                                <NavLink activeclassname="active" className="nav-link" to="/about">About Us</NavLink>
                                <NavLink activeclassname="active" className="nav-link" to="/video-library">Video Library</NavLink>
                                <NavLink activeclassname="active" className="nav-link" to="/gallery">Gallery</NavLink>
                                {/* <NavLink activeclassname="active" className="nav-link" to="/media-library">Media Library</NavLink> */}
                                {/* <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
                            </NavDropdown>
                            <NavLink activeclassname="active" className="nav-link" to="/clinic-partners">Clinic Partners</NavLink>
                            <NavLink activeclassname="active" className="nav-link" to="/our-journey">Our Journey</NavLink>
                            <NavLink activeclassname="active" className="nav-link" to="/media-projects">Media Projects</NavLink>
                            <NavLink activeclassname="active" className="nav-link" to="/book-a-consultation">Book a Consultation</NavLink>
                            {/* <NavLink activeclassname="active" className="nav-link" to="/events">Events</NavLink> */}
                            <a activeclassname="active" className="nav-link" href="https://twodadsuk.com/blog/">Blog</a>
                            <NavLink activeclassname="active" className="nav-link" to="/contact">Contact Us</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;