import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import data from '../data/mediaprojdata.json';
//import MediaProjFilter from '../shared/mediafilter'
import {Container, Row} from 'react-bootstrap';
import YouTube from 'react-youtube';

function MediaProj(){

const opts = {
    height: '100%',
    width: "100%",
    playerVars: {
        autoplay: 0,
    },
};
      
// data
const MEDPROJS = [
    {   category:"nature",
        imgUrl:"../resources/images/girls-img-01.jpg",
        videoId:"cHz90ZOpW7Q",
        title:"TwoDads UK World AIDS Day special podcast ft. special guests",
        postpara: "<p>One of TwoDads UK missions, listed on our website (www.TwoDadsUK.com) is to campaign for equal fertility treatment of those living with HIV, that want to access Surrogacy...</p>",
        postparafull: "<p>One of TwoDads UK missions, listed on our website (www.TwoDadsUK.com) is to campaign for equal fertility treatment of those living with HIV, that want to access Surrogacy as a way to build their family. <br/>In the UK if you are HIV+ you cannot explore UK Surrogacy under the restrictions by the HFEA (the UK regulator).  However, if you are heterosexual and you're HIV+, and wanting to have IVF, this is permitted. We want to make fertility treatment fairer, for those wanting to explore Surrogacy, and we need more of you to support us and share this mission.</p>",
        HeightClass:"reg"
    },
    {   category:"adventure",
        imgUrl:"../resources/images/girl-img.jpg",
        videoId:"enamsXwkz8Y",
        title:"The Modern Family Show 2021 - An Intro",
        postpara: "<p>This one-day boutique event is the only UK family creation show exclusively designed for the LGBT+ community informing attendees about UK and International family building options, inc Surrogacy and IVF/IUI, Adoption ..</p>",
        postparafull: "<p>This one-day boutique event is the only UK family creation show exclusively designed for the LGBT+ community informing attendees about UK and International family building options, inc Surrogacy and IVF/IUI, Adoption, Fostering, Co-Parenting, Fertility Preservation, Solo Parenting, and Egg/Sperm Donation.</p><p>DESIGNED AND MANAGED BY GAY PARENTS<br/>Having an event completely designed around the needs of the LGBT+ community, designed by gay parents is long overdue.  Their passion and dedication have led them to create this unique family creation event  in the heart of Central London at the iconic 8 Northumberland taking place on Saturday 20th March 2021.</p>",
        HeightClass:"lg"
    },
    {   category:"romantic",
        imgUrl:"../resources/images/two-dads-with-children.jpg",
        videoId:"0hM19peqCQk",
        title:"TwoDads UK and IVF Babble team up to form IVF Babble LGBTQ",
        postpara:"<p>During Pride month IVF Babble reflect on how far they have come, in terms of equality for the LGBTQ+ community, but also how much more they are passionate about doing in supporting everyone trying to conceive.</p>",
        postparafull:"<p>During Pride month IVF Babble reflect on how far they have come, in terms of equality for the LGBTQ+ community, but also how much more they are passionate about doing in supporting everyone trying to conceive.</p><p>‘Whilst we already have a space on IVFbabble.com for the LGBTQ community, we knew we needed to do more’ says Sara Marshall-Page and Tracey Bambrough, co founders of IVFbabble.com</p><p>‘This is why IVF Babble are enhancing and evolving the work they already do for the TTC community, and this new collaboration not only launches a new era of IVF Babble, but it’s the beginning of an exciting chapter with our friends at TwoDads U.K - something we’re all very excited about.’</p>",
        HeightClass:"reg"
    }
  ];
  
  // get unique category items
  const uniqueItems = (x, i, array) => array.indexOf(x) === i;
  const MEDPROJ_CATEGORIES = MEDPROJS.map(proj => proj.category).filter(
    uniqueItems
  );
  
  MEDPROJ_CATEGORIES.sort().unshift("all");

    return(
        <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={300} className="section mediaprojsec">
            <Container>
            {
               data.mediaproj.map((item,i)=>{
                   return(
                        <div key={`ContBoxList-list-1-${i}`}>
                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={300}><h2 className="main-tl">{item.title}</h2></ScrollAnimation>
                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={300}><p className="spl-txt">{item.para}</p></ScrollAnimation>
                            
                            {/* <MediaProjFilter medprojs={MEDPROJS} medprojCategories={MEDPROJ_CATEGORIES}/> */}
                            <Row className="ContBoxList row-grid no-gutters">
                            {
                                MEDPROJS.map((post,i)=>{
                                    return(
                                        <div key={`ContBoxList-list-1-${i}`} className="col-lg-4">
                                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={400} >
                                                <div className="ContBox CptBtm">
                                                    <div className="Cont-vid"><YouTube videoId={post.videoId} opts={opts}/></div>
                                                    {/* <div className="Cont-img"><img src={post.imgUrl} alt=""/></div> */}
                                                    <div className="Cont-txt">
                                                        <h3>{post.title}</h3>
                                                        <div dangerouslySetInnerHTML={{__html:post.postpara}}></div>
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                    )

                                })
                            }
                            </Row>
                                        <ScrollAnimation className="btn-container text-center" animateIn='fadeInUp' animateOnce={true} delay={500}>
<a href="https://www.youtube.com/channel/UCdYEkST38nzX_5FgoBpx1Xg" target="blank" className="btn btn-primary btn-view more">View All</a> 
                                        </ScrollAnimation>
                        </div>
                   )
               }) 
            }
            </Container>
        </ScrollAnimation>
    )
}

export default MediaProj;