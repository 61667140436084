import React from 'react';

const data = {
    "socialicons":[
        {
            "name":"facebook",
            "iconame":"",
            "linkUrl":"https://www.facebook.com/twodads.u.k/" 
        },
        {
            "name":"instagram",
            "iconame":"",
            "linkUrl":"https://www.instagram.com/twodads.u.k/" 
        },
        {
            "name":"youtube",
            "iconame":"",
            "linkUrl":"https://www.youtube.com/channel/UCdYEkST38nzX_5FgoBpx1Xg" 
        },
        // {
        //     "name":"twitter",
        //     "iconame":"",
        //     "linkUrl":"https://twitter.com/twodads_UK" 
        // },
        // {
        //     "name":"threads",
        //     "iconame":"",
        //     "linkUrl":"https://www.threads.net/@twodads.u.k" 
        // }
    ]
}

function SocialIcons(){
    return(
        <div className="btn-container">
            <ul className="social-ico-list">
                {data.socialicons.map((icon, i)=>
                    {
                        return(
                            <li key={`socialicons-list-${i}`}><a href={icon.linkUrl} target="_blank" rel="noopener noreferrer"><i className= {`icon fa fa-${icon.name}`}></i></a></li>
                        )
                    })
                }
            </ul>
        </div>
    )

}

export default SocialIcons;