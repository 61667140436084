import React from 'react';
import {Container} from 'react-bootstrap'

import Header from '../shared/header';
import Footer from '../shared/footer';
import StaticBanner from '../shared/staticbanner';

import data from '../data/PrivacyPolicyData.json';

function PrivacyPolicy(){
    document.title ="Privacy Policy"; 
    document.getElementsByTagName("META")[2].content="";
    document.getElementsByTagName("META")[3].content="";
    document.querySelector("link[rel='canonical']").setAttribute("href","https://www.twodadsuk.com/privacy-policy");
    
    return(
        <div className="wrapper">
            <Header/>
            {data.BannerData.map((item, i)=>{
                    return <StaticBanner BannerCont={item} key={`BannerData-list-${i}`}/>
                })
            }
            <div className="section">
                <Container>
                {data.PrivacypolicySec.map((item, i)=>{
                        return <div dangerouslySetInnerHTML={{__html:item.para}}></div>
                    })
                }
                    
                </Container>
            </div>
            <Footer/>
        </div>
    );
}

export default PrivacyPolicy;