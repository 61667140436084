import React, { Component } from "react";

class NewsLetter extends Component {
  componentDidMount() {
  	const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
    	if(window.hbspt) {
      	window.hbspt.forms.create({
        	portalId: '25374820',
          formId: '51476731-fb87-4f45-9c8b-d9729b44ab93',
          target: '#hubspotForm'
        })
      }
    });
  }
  render() {
    return (
      <div>
        <div id="hubspotForm"></div>
      </div>
    );
  }
}

export default NewsLetter;
