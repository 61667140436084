import React, { useEffect, useMemo, useState } from "react";
import StackGrid from "react-stack-grid";
//import { Link } from 'react-router-dom';

// const ProductItem = ({ imgUrl, title, postpara, category }) => (
//     <li className="ContBox CptOver">
//         <div className="Cont-img"><img src={imgUrl} alt=""/></div>
//         <div className="Cont-txt">
//             <h3>{title}</h3>
//             <p>{postpara}</p>
//         </div>
//     </li>
//   );

const ProductItems = ({
  state: { blogposts, displayCategory, displayImgClass },
}) => {

  const [columnWidth, setColumnWidth] = useState('33.33%');

  useEffect(() => {
    const setWidth = () => {
      setColumnWidth(window.innerWidth > 599 ? '33.33%' : '50%');
    };

    window.addEventListener("resize", setWidth, false);
    setWidth();

    return () => {
      window.removeEventListener("resize", setWidth);
    }
  }, [])

  const posts = useMemo(() => {
    let hasBigImage = false;
    let posts = blogposts.filter(({ category, bigImage }) => {
      const validPost =
        displayCategory === category || displayCategory === "all";
      if (validPost && bigImage.indexOf("Yes") >= 0) {
        hasBigImage = true;
      }
      return validPost;
    });

    return hasBigImage ? posts.slice(0, 5) : posts.slice(0, 6);
  }, [blogposts, displayCategory]);

  return (
    <div className="ContBoxList">
      <StackGrid columnWidth={columnWidth}>
        {posts.map(({ link, image, title, content, category, bigImage }, i) => {
          return (
            <a key={`ContBox-${i}`} href={link} className="ContBox CptOver">
              <div className="Cont-img">
                <img src={image} className={bigImage} alt="" />
              </div>
              <div className="Cont-txt">
                <h3>{title.rendered}</h3>
                <p dangerouslySetInnerHTML={{ __html: content.rendered }}></p>
              </div>
            </a>
          );
        })}
      </StackGrid>
    </div>
  );
};

const ButtonCategories = (blogpostCategories, setCategory) =>
  blogpostCategories.map((category) => (
    <>
    {category !== '' ? (<button
      key={category}
      className={`btn-${category}`}
      onClick={() => setCategory(category)}
    >
      {category}
    </button>):('')
    }
    </>
  ));

const UI = ({ state, state: { blogpostCategories }, setCategory }) => (
  <div className="">
    <div className="d-flex justify-content-center tab-btn">
      {ButtonCategories(blogpostCategories, setCategory)}
    </div>

    <ProductItems state={state} />
  </div>
);

export default class BlogPostFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayCategory: "all",
      displayImgClass: "Yes",
      blogposts: props.blogposts,
      blogpostCategories: props.blogpostCategories,
      blogpostImgClass: props.blogpostImgClass,
    };
    this.setCategory = this.setCategory.bind(this);
    this.setImgClassName = this.setImgClassName.bind(this);
  }
  setCategory(category) {
    this.setState({
      displayCategory: category,
    });
  }
  setImgClassName(ImgClass) {
    this.setState({
      displayImgClass: ImgClass,
    });
  }
  render() {
    return (
      <UI
        setCategory={this.setCategory}
        setImgClassName={this.setImgClassName}
        state={this.state}
      />
    );
  }
}
