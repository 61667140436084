import React from 'react';

import Header from '../shared/header';
import Footer from '../shared/footer';
import StaticBanner from '../shared/staticbanner';

import OurWeddingStory from '../shared/ourweddingstory';
import OurJourneyTimeLine from '../shared/ourjourneytimeline';

import data from '../data/OurJournyData.json';

function OurJourney(){
    document.title ="Our Journey - TwoDads U.K - Surrogacy Support in UK"; 
    document.getElementsByTagName("META")[2].content="Find the best and unforgettable moments of our lives, we love to cherish them. See our Journey of Love and becoming Dads.";
    document.getElementsByTagName("META")[3].content="Two Dads in UK, Surrogacy in UK, Gay Parents with Surrogacy Support, Surrogacy Support to grow your family, Daddies change too, Gay parents, Gay Couple, Two Dads, Our Journey of being dads, Our Life Journey";
    document.querySelector("link[rel='canonical']").setAttribute("href","https://www.twodadsuk.com/our-journey");
        
    return(
        <div className="wrapper">
            <Header/>
            {data.BannerData.map((item, i)=>{
                    return <StaticBanner BannerCont={item} key={`BannerData-list-${i}`}/>
                })
            }
            <OurWeddingStory/>
            <OurJourneyTimeLine/>
            <Footer/>
        </div>
    );
}

export default OurJourney;