import React, {Component} from 'react';
import { Container } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';

class HighlightText extends Component{
    render(){
        const {HighlightData} = this.props;

        setTimeout(function(){
            const SplTxt = document.querySelectorAll('.spl-txt');
             for(let i=0; i< SplTxt.length; i++){
                const SplTxtP = SplTxt[i].querySelectorAll('p');
                if(SplTxtP.length > 1){
                    const aTagWrap = document.createElement('div');
                    const aTag = document.createElement('a');
                    aTagWrap.setAttribute('class','btn-container');
                    aTag.setAttribute('href','#');
                    aTag.setAttribute('class',"btn btn-primary btn-view more");
                    aTag.innerText = "View More";
                    aTagWrap.appendChild(aTag);
                    SplTxt[i].appendChild(aTagWrap);
                    
                    const SplPHeight = SplTxt[i].querySelector('p').offsetHeight + SplTxt[i].querySelector('.btn-container').offsetHeight;
                    SplTxt[i].style.paddingBottom = `${SplTxt[i].querySelector('.btn-container').offsetHeight}px`;
                    SplTxt[i].style.maxHeight = `${SplPHeight}px`;
                    SplTxt[i].style.overflow = 'hidden';
                    SplTxt[i].classList.add("full-view");

                    function modifyText(e) {
                        e.preventDefault();
                        const t2 = SplTxt[i].querySelector(".btn-view");
                        if (t2.firstChild.nodeValue === "View More") {
                            SplTxt[i].style.maxHeight = 'none';
                            aTag.innerText = "View Less";
                            aTag.setAttribute('class',"btn btn-primary btn-view less");
                            SplTxt[i].classList.remove("full-view");
                        } else if(t2.firstChild.nodeValue === "View Less") {
                            SplTxt[i].style.maxHeight = `${SplPHeight}px`;
                            SplTxt[i].style.overflow = 'hidden'; 
                            aTag.innerText = "View More";
                            aTag.setAttribute('class',"btn btn-primary btn-view more");
                            SplTxt[i].classList.add("full-view");
                        }else{                            
                            SplTxt[i].style.maxHeight = `${SplPHeight}px`;
                            SplTxt[i].style.overflow = 'hidden'; 
                            aTag.innerText = "View More";
                            aTag.setAttribute('class',"btn btn-primary btn-view more");
                        }
                    }
                    const el = SplTxt[i].querySelector(".btn-view");
                    el.addEventListener("click", modifyText);
                }
             }
        },800);

        return(
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={200} className="section HighlightSec" >
                <Container>
                    <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={400}><h2 className="main-tl">{HighlightData.title}</h2></ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={600}><div className="spl-txt" dangerouslySetInnerHTML={{__html:HighlightData.para}}></div></ScrollAnimation>
                </Container>
            </ScrollAnimation>
        )
    }
}

export default HighlightText;