import React from 'react';

import Header from '../shared/header';
import Footer from '../shared/footer';
import StaticBanner from '../shared/staticbanner';

import data from '../data/pagenotfoundData.json'


function Notfound(){
    document.title ="Page Not found"; 
    document.getElementsByTagName("META")[2].content="";
    document.getElementsByTagName("META")[3].content="";
    document.querySelector("link[rel='canonical']").setAttribute("href","");
    
    return(
        <div className="wrapper">
            <Header/>         
            {data.BannerData.map((item, i)=>{
                    return <StaticBanner BannerCont={item} key={`PGNFBanner-${i}`}/>
                })
            }
            <Footer/>
        </div>
    )
}

export default Notfound;
