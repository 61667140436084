import React from 'react';
import {Container} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll'
import YouTube from 'react-youtube';

import data from '../data/MediaProjectsData.json';

const MediaProjects = () => {
    const opts = {
        height: '100%',
        width: "100%",
        playerVars: {
            autoplay: 0,
        },
    };
    return(
        <>
        <div className="section timeLineSec">
            {
                data.MediaProjectsSec.map((item,i)=>{
                    return(
                        <Container key={`Container-list-${i}`}>
                            <ul className="timeLineList video">
                            {
                                item.MediaProjectsSecList.map((OjItem,i)=>{
                                    return(
                                        <li className={'image-'+(i%2 ? 'right':'left')} key={`MediaProjectsSec-lists-${i}`}>
                                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={300}>
                                                <div className="d-md-inline-flex align-items-center">
                                                    <div className="img-sec">
                                                        {OjItem.videoId !== '' ? <div className="Cont-vid"><YouTube videoId={OjItem.videoId} opts={opts}/></div>:''}
                                                        {OjItem.ImgUrl !== '' ? <img src={OjItem.ImgUrl} alt=""/>:''}
                                                        {OjItem.iframVid !== '' ? <iframe src={OjItem.iframVid} width="100%" height="360" frameborder="0" title={OjItem.title} allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>:''}
                                                    </div>
                                                    <div className="cont-sec">
                                                        <h3>
                                                            {OjItem.moreLink !== '' ? <a href={OjItem.moreLink}>{OjItem.title}</a>: <>{OjItem.title}</>}
                                                        </h3>
                                                        <div dangerouslySetInnerHTML={{__html:OjItem.para}}></div>
                                                        <p className="year-txt">{OjItem.year}</p>
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                        </li>
                                    )
                                })
                            }
                            </ul>
                        </Container>
                    )
                })
            }
        </div>
        </>
    );
}

export default MediaProjects;