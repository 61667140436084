import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import Header from '../shared/header';
import Footer from '../shared/footer';
import StaticBanner from '../shared/staticbanner';
import BlogSec from '../shared/blogsec'

import data from '../data/blogsecdata.json'

function Blog(){
    document.title ="Blog"; 
    document.getElementsByTagName("META")[2].content="";
    document.getElementsByTagName("META")[3].content="";

    return(
        <div className="wrapper">
            <Header/>
            {data.blogPost.map((item, i)=>{
                    return <StaticBanner BannerCont={item} key={`Blogdata-${i}`}/>
                })
            }
            <Row className="no-gutters">
            {data.blogOpts.map((item,i)=>{
                    return(
                        <Col key={`blogOpts-list-${i}`} >
                            <Link to={item.secUrl} className="wrapbox">
                                <div className="img-sec"><img src={item.imgUrl} alt=""/></div>
                                <div className="text-cont">
                                    <h2>{item.secName}</h2>
                                </div>
                            </Link>
                        </Col>
                    )
                })
            }
            </Row>
            <BlogSec/>
            <Footer/>
        </div>
    );
}

export default Blog;