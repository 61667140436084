import React from 'react';
// import { Form, Col, Button } from 'react-bootstrap';

class FormSec extends React.Component {
  componentDidMount() {
  	const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
    	if(window.hbspt) {
      	window.hbspt.forms.create({
        	portalId: '25374820',
          formId: '1c2392f6-84b2-4b29-b63b-9907ca2db52c',
          target: '#ContactHubspotForm'
        })
      }
    });
  }

//   <script charset="utf-8" type="text/javascript" src="//js-eu1.hsforms.net/forms/embed/v2.js"></script>
// <script>
//   hbspt.forms.create({
//     region: "eu1",
//     portalId: "25374820",
//     formId: "1c2392f6-84b2-4b29-b63b-9907ca2db52c"
//   });
// </script>
  // constructor(props) {
  //   super(props);
  //   this.state = { username: '', useremail: '', userphone: '', usermessage: '' };
  // }

  // // validated = useState(false);
  // // setValidated = useState(false);

  // handleSubmit = (event) => {
  //   // console.log(event.currentTarget);
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     console.log("test");
  //   } else {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     console.log("test1");
  //     console.log(this.state.username);

  //     fetch('https://www.anycation.com/mailerContactTwoDads.php', {
  //       crossDomain:true, 
  //       method: 'POST',
  //       headers: {
  //         // 'Accept': 'application/json',
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //       // headers: {'Access-Control-Allow-Origin':'*',"Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",'Content-Type':'application/json'},
  //       // We convert the React state to JSON and send it as the POST body
  //       // body: JSON.stringify(this.state)

  //       body: "contact_name="+this.state.username+"&contact_email="+this.state.useremail+"&contact_phone="+this.state.userphone+"&message="+this.state.usermessage,
  //     }).then(function(response) {
  //       console.log(response)
  //       return response.json();
  //     });
  //   }

  //   // setValidated(true);
  // };

  // nameHandler = (event) => {
  //   this.setState({username: event.target.value});
  // }
  // emailHandler = (event) => {
  //   this.setState({useremail: event.target.value});
  // }
  // phoneHandler = (event) => {
  //   this.setState({userphone: event.target.value});
  // }
  // msgHandler = (event) => {
  //   this.setState({usermessage: event.target.value});
  // }
  render() {
    return (
      <>
      <div id="ContactHubspotForm"></div>

      {/* <Form noValidate onSubmit={this.handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} md="12" controlId="validationName">
            <Form.Control
              required
              type="text"
              placeholder="Name"
              defaultValue=""
              onChange={this.nameHandler}
            />
            <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="validationEmail">
            <Form.Control
              required
              type="text"
              placeholder="E-mail"
              defaultValue=""
              onChange={this.emailHandler}
            />
            <Form.Control.Feedback type="invalid">Please provide a valid Email Id</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="validationMobile">
            <Form.Control 
            type="tel" 
            placeholder="Mobile" 
            required 
            onChange={this.phoneHandler}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid Mobile Number.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="validationDetails">
              <Form.Control 
              as="textarea" 
              placeholder="Details" 
              rows="3" 
              name="message"
              onChange={this.msgHandler} />
          </Form.Group>
        </Form.Row>
        <Button type="submit">Submit</Button>
      </Form> */}
      </>
    );
  }
}
export default FormSec;
// ReactDOM.render(<FormSec />, document.getElementById('root'));