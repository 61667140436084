import React from 'react';
import {Container} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll'
// import { LightBoxGallery, GalleryItem } from 'react-magnific-popup';


import HighlightText from '../shared/highlighttext';

import data from '../data/OurJournyData.json';

// const config = {
// 	delegate: 'a',
// 	type: 'image',
// 	tLoading: 'Loading image #%curr%...',
// 	mainClass: 'mfp-img-mobile',
// 	gallery: {
// 		enabled: true,
// 		navigateByImgClick: true,
// 		preload: [0,1] // Will preload 0 - before current, and 1 after the current image
// 	},
// 	image: {
// 		tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
// 		titleSrc: function(item) {
// 			return item.el.attr('title') + '<small>by Marsel Van Oosten</small>';
// 		}
// 	}
// }

const OurJourneyTimeLine = () => {
    return(
        <>
        <div className="section timeLineSec">
            {
                data.OurJourneySec.map((item,i)=>{
                    return(
                        <Container key={`Container-list-${i}`}>
                            <HighlightText HighlightData={item} key={`OurJourneySec-list-${i}`} />
                            {/* <LightBoxGallery 
                            className="popup-gallery"
                            config={config}
                            > */}
                            <ul className="timeLineList">
                            {
                                item.OurJourneySecList.map((OjItem,i)=>{
                                    return(
                                        <li className={'image-'+(i%2 ? 'left':'right')} key={`OurJourneySec-lists-${i}`}>
                                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={300}>
                                                <div className="d-lg-inline-flex">
                                                    <div className="img-sec">
                                                        {/* <GalleryItem
                                                            href={OjItem.ImgUrl} 
                                                            title={OjItem.year}
                                                        >
                                                            <img src={OjItem.ImgUrl} alt=""/>
                                                        </GalleryItem> */}
                                                            <img src={OjItem.ImgUrl} alt=""/>
                                                        
                                                    </div>
                                                    <div className="cont-sec">
                                                        <div dangerouslySetInnerHTML={{__html:OjItem.title}}></div>
                                                        <p className="year-txt">{OjItem.year}</p>
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                        </li>
                                    )
                                })
                            }
                            </ul>
                            {/* </LightBoxGallery> */}
                        </Container>
                    )
                })
            }
            </div>
        </>
    );
}

export default OurJourneyTimeLine;