import React from 'react';

import Header from '../shared/header';
import Footer from '../shared/footer';
import StaticBanner from '../shared/staticbanner';

import FollowSec from '../shared/followSec';
import ContactSec from '../shared/contactsec';
import AEPSec from '../shared/AEPSec';

import data from '../data/contactData.json'

function Contact(){
    document.title ="Contact Us - TwoDads U.K - Surrogacy Support in UK"; 
    document.getElementsByTagName("META")[2].content="Contact Us to know more about Surrogacy Journey, About us. Let's connect. We are happy to hear from you.";
    document.getElementsByTagName("META")[3].content="Two Dads in UK, Surrogacy in UK, Gay Parents with Surrogacy Support, Surrogacy Support to grow your family, Daddies change too, Gay parents, Gay Couple, Two Dads, Contact Us, Know more about Surrogacy in UK";

    document.querySelector("link[rel='canonical']").setAttribute("href","https://www.twodadsuk.com/contact");
    
    return(
        <div className="wrapper">
            <Header/>
            { data.bannerdata.map((item, i)=>{
                return <StaticBanner BannerCont={item} key={`banner-list-${i}`}/> 
            })}
            <AEPSec/> 
            {/* <FollowSec/> */}
            <ContactSec/>
            <Footer/>
        </div>
    );
}

export default Contact;