import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import Carousel from 'react-elastic-carousel';
import YouTube from 'react-youtube';

import Header from '../shared/header';
import Footer from '../shared/footer';
import StaticBanner from '../shared/staticbanner';

import data from '../data/VideoLibraryData.json';

const breakPoints = [
    { width: 1, itemsToShow: 2, itemsToScroll: 1 },
    { width: 375, itemsToShow: 3, itemsToScroll: 1 },
    { width: 850, itemsToShow: 3 }
]
const opts = {
    height: '100%',
    width: "100%",
    playerVars: {
        autoplay: 0,
    },
};

function VideoLibrary(){
    document.title ="Video Library - TwoDads U.K - Surrogacy Support in UK"; 
    document.getElementsByTagName("META")[2].content="Covid Support Video Library, Parenting Tips, Best beats of Talulah.";
    document.getElementsByTagName("META")[3].content="Two Dads in UK, Surrogacy in UK, Gay Parents with Surrogacy Support, Surrogacy Support to grow your family, Daddies change too, Gay parents, Gay Couple, Two Dads";
    document.querySelector("link[rel='canonical']").setAttribute("href","https://www.twodadsuk.com/video-library");
    
    return(
        <div className="wrapper">
            <Header/>
            {data.BannerData.map((item, i)=>{
                    return <StaticBanner BannerCont={item} key={`BannerData-list-${i}`}/>
                })
            }
            <div className="section video-library">
                <Container>
                    <Row>
                        {
                            data.Covid19Seriesone.map((item, i)=>{
                                return(
                                        <Col lg={12} key={`FriendTwoDadsSec-list-${i}`}>
                                            <h2 className="sub-tl" animateIn='fadeInUp' animateOnce={true} delay={600}>{item.title}</h2>
                                            <div className="icon-list-wrap" animateIn='fadeInUp' animateOnce={true} delay={700}>
                                                <div className="icon-list ContBoxList">
                                                    <Carousel breakPoints={breakPoints}>
                                                        {
                                                            item.VideoLibrarySecList.map((List, i=500)=>{
                                                                return(
                                                                <ScrollAnimation className="cara-item" animateIn='fadeInUp' animateOnce={true}>
                                                                    <div className="ContBox CptBtm">
                                                                        <div className="Cont-vid"><YouTube videoId={List.videoId} opts={opts}/></div>
                                                                        <div className="Cont-txt">
                                                                            <h3>{List.title}</h3>
                                                                            <div dangerouslySetInnerHTML={{__html:List.para}}></div>
                                                                        </div>
                                                                    </div>
                                                                </ScrollAnimation>
                                                                )
                                                            })
                                                        }
                                                    </Carousel>
                                                </div>
                                            </div>
                                        </Col>
                                )
                            })
                        }
                        </Row>
                </Container>
            </div>
            <div className="section video-library diff">
                <Container>
                    <Row>
                        {
                            data.Covid19Seriestwo.map((item, i)=>{
                                return(
                                        <Col lg={12} key={`FriendTwoDadsSec-list-${i}`}>
                                            <h2 className="sub-tl" animateIn='fadeInUp' animateOnce={true} delay={600}>{item.title}</h2>
                                            <div className="icon-list-wrap" animateIn='fadeInUp' animateOnce={true} delay={700}>
                                                <div className="icon-list ContBoxList">
                                                    <Carousel breakPoints={breakPoints}>
                                                        {
                                                            item.VideoLibrarySecList.map((List, i=500)=>{
                                                                return(
                                                                <ScrollAnimation className="cara-item" animateIn='fadeInUp' animateOnce={true}>
                                                                    <div className="ContBox CptBtm">
                                                                        <div className="Cont-vid"><YouTube videoId={List.videoId} opts={opts}/></div>
                                                                        <div className="Cont-txt">
                                                                            <h3>{List.title}</h3>
                                                                            <div dangerouslySetInnerHTML={{__html:List.para}}></div>
                                                                        </div>
                                                                    </div>
                                                                </ScrollAnimation>
                                                                )
                                                            })
                                                        }
                                                    </Carousel>
                                                </div>
                                            </div>
                                        </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </div>
            <div className="section video-library">
                <Container>
                    <Row>
                        {
                            data.TalulahsBestBits.map((item, i)=>{
                                return(
                                    <Col lg={12} key={`FriendTwoDadsSec-list-${i}`}>
                                        <h2 className="sub-tl" animateIn='fadeInUp' animateOnce={true} delay={600}>{item.title}</h2>
                                        <div className="icon-list-wrap" animateIn='fadeInUp' animateOnce={true} delay={700}>
                                            <div className="icon-list ContBoxList">
                                                <Carousel breakPoints={breakPoints}>
                                                    {
                                                        item.VideoLibrarySecList.map((List, i=500)=>{
                                                            return(
                                                            <ScrollAnimation className="cara-item" animateIn='fadeInUp' animateOnce={true}>
                                                                <div className="ContBox CptBtm">
                                                                    <div className="Cont-vid"><YouTube videoId={List.videoId} opts={opts}/></div>
                                                                    <div className="Cont-txt">
                                                                        <h3>{List.title}</h3>
                                                                        <div dangerouslySetInnerHTML={{__html:List.para}}></div>
                                                                    </div>
                                                                </div>
                                                            </ScrollAnimation>
                                                            )
                                                        })
                                                    }
                                                </Carousel>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </div>
            <div className="section video-library">
                <Container>
                    <Row>
                        {
                            data.TwoDadsReviews.map((item, i)=>{
                                return(
                                    <Col lg={12} key={`FriendTwoDadsSec-list-${i}`}>
                                        <h2 className="sub-tl" animateIn='fadeInUp' animateOnce={true} delay={600}>{item.title}</h2>
                                        <div className="icon-list-wrap" animateIn='fadeInUp' animateOnce={true} delay={700}>
                                            <div className="icon-list ContBoxList">
                                                <Carousel breakPoints={breakPoints}>
                                                    {
                                                        item.VideoLibrarySecList.map((List, i=500)=>{
                                                            return(
                                                            <ScrollAnimation className="cara-item" animateIn='fadeInUp' animateOnce={true}>
                                                                <div className="ContBox CptBtm">
                                                                    <div className="Cont-vid"><YouTube videoId={List.videoId} opts={opts}/></div>
                                                                    <div className="Cont-txt">
                                                                        <h3>{List.title}</h3>
                                                                        <div dangerouslySetInnerHTML={{__html:List.para}}></div>
                                                                    </div>
                                                                </div>
                                                            </ScrollAnimation>
                                                            )
                                                        })
                                                    }
                                                </Carousel>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Container>
            </div>
            <Footer/>
        </div>
    );
}

export default VideoLibrary;