import React, { useEffect } from 'react';
import './App.css';

import Home from './pages/home';
import Events from './pages/events';
import About from './pages/about';
import OurJourney from './pages/our-journey';
import OurPartners from './pages/clinic-partners';
import Gallery from './pages/gallery'
import Contact from './pages/contact'
import Blog from './pages/blog';
import MediaProjects from './pages/media-projects';
import VideoLibrary from './pages/video-library';
// import MediaLibrary from './pages/media-library';
import PrivacyPolicy from './pages/privacy-policy';
import TermsandConditions from './pages/terms-conditions';
import BookConsultation from './pages/book-a-consultation';
import Notfound from './pages/notfound';
import ScrollToTop from './shared/ScrollToTop';

import { Route, Switch, withRouter, BrowserRouter as Router} from 'react-router-dom'




function App() {
    return (
        <div className="App">
            <Router>
                <ScrollToTop />
                <Switch>
                    <Route exact={true} path="/" component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/gallery" component={Gallery} />
                    <Route path="/events" component={Events} />
                    <Route path="/blog" component={Blog} />
                    <Route path="/our-journey" component={OurJourney} />
                    <Route path="/book-a-consultation" component={BookConsultation} />
                    <Route path="/clinic-partners" component={OurPartners} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/media-projects" component={MediaProjects} />
                    <Route path="/video-library" component={VideoLibrary} />
                    {/* <Route exact path="/media-library" component={MediaLibrary} /> */}
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/terms-conditions" component={TermsandConditions} />
                    <Route component={Notfound} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
