import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReadMoreReact from 'read-more-react';

import Header from '../shared/header';
import Banner from '../shared/banner';
import Footer from '../shared/footer';
import BlogSec from '../shared/blogsec';
import MediaProj from '../shared/mediaprojsec';
import ContactSec from '../shared/contactsec';
import FriendsSec from '../shared/friendsSec';
import OurVision from '../shared/ourvision';
import OurMission from '../shared/ourmission';
import HighlightText from '../shared/highlighttext';
import OurSocialFeeds from '../shared/oursocialfeeds';
import data from '../data/homeData';
import ScrollAnimation from 'react-animate-on-scroll';

function Home(){  
    document.title ="TwoDads U.K - Surrogacy Support in UK"; 
    document.getElementsByTagName("META")[2].content="We (Wes and Michael) are on a mission to help raise awareness of Same-Sex Parenting and help normalise our own modern family, as we hopefully help shine the spotlight, positively on families like ours, with either two mommies or two daddies.  We’re already very proud to have already supported implementing change with the introduction of new guidance to Healthcare professionals issued by the Department of Health advising how to fairly treat Surrogates and Intended Parents (IP’s).";
    document.getElementsByTagName("META")[3].content="Two Dads in UK, Surrogacy in UK, Gay Parents with Surrogacy Support, Surrogacy Support to grow your family, Daddies change too, Gay parents, Gay Couple, Two Dads";
    document.querySelector("link[rel='canonical']").setAttribute("href","https://www.twodadsuk.com");

        return(
            <div className='wrapper' id="wrapper">
                <div className="bg-shade type-01">
                    <Header/>
                    <Banner/>
                    {
                        data.SurogacySec.map((item, i)=>{
                            return<HighlightText HighlightData={item} key={`SurogacySec-list-${i}`}/>
                        })
                    }
                </div>
                <ScrollAnimation className="section TwoDadsSec" animateIn='fadeInUp' animateOnce={true} delay={200}>
                {
                    data.TwoDadsSec.map((item, i) => {
                        return(
                            <Container key={`TwoDadsSec-list-${i}`}>
                                <Row xs={1} sm={2} >
                                    <Col className="cont-sec">
                                        <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={300} >
                                        <h2 className="main-tl">{item.title}</h2>
                                        <ReadMoreReact 
                                            text={item.SecText}
                                            min={100}
                                            ideal={300}
                                            max={400}
                                            readMoreText= 'View More'/>
                                        </ScrollAnimation>
                                    </Col>
                                    <Col>
                                        <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={300} className="big-img"><img src={item.twoDadsImg} alt="" /></ScrollAnimation>
                                    </Col>
                                </Row>
                                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={500} className="sub-Img"><img src={item.twoDadsSubImg} alt="" /></ScrollAnimation>
                            </Container>
                        )
                    })
                }
                </ScrollAnimation>
                <ScrollAnimation className="section static-banners" animateIn='fadeInUp' animateOnce={true} delay={300}>
                    <img src="../resources/images/TDUK-rainbow-awards-strip.jpg" alt="TDUK Rainbow Awards"/>
                </ScrollAnimation>

                <ScrollAnimation className="section podcast-sec" animateIn='fadeInUp' animateOnce={true} delay={300}>
                    <Container>
                    <iframe title="podcast" src="https://embed.acast.com/034e266c-e951-4b7f-a38b-880af79888bf" frameBorder="0" allow="autoplay" width="100%" height="110"></iframe>
                    </Container>
                </ScrollAnimation>

                <OurVision/>
                <OurMission/>
                <FriendsSec/>
                {/* <FollowSec/> */}
                {/* <OurSocialFeeds/> */}
                <BlogSec/>
                <MediaProj/>
                <ContactSec/>
                {/* <Main products={PRODUCTS} productCategories={PRODUCT_CATEGORIES} /> */}
                <Footer/>
            </div>
        );
}

export default Home;