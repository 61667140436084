import React from "react";
import { Container } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
//import {Link} from 'react-router-dom';

import data from "../data/homeData";
import { Player, BigPlayButton, LoadingSpinner, ControlBar } from "video-react";

function Banner() {
  //const [showVideo, setShowVideo] = useState(false);

  // setTimeout(function () {
  //   var bannerTpPad = document.getElementById("header").offsetHeight;
  //   document.querySelector(".banner").style.paddingTop = `${bannerTpPad}px`;
  // }, 1200);

  // const onClick = () => {
  //   //console.log("nar called");
  //   if (showVideo) {
  //     return;
  //   }
  //   setShowVideo(true);
  // };

  return (
    <div className="banner">
    {/* <div className="banner" onClick={onClick}> */}
      <Container>
        {data.BannerData.map((item, i) => {
          return (
            <ScrollAnimation
              className="slide"
              animateIn="fadeInUp"
              animateOnce={true}
              delay={100}
              key={`BannerData-list-${i}`}
            >
              <ScrollAnimation
                className="banner-img"
                animateIn="fadeInUp"
                animateOnce={true}
                delay={200}
              >
                <Player
                    playsInline
                    poster={item.BannerImg}
                    src={item.BannerVideo}
                  >
                    <BigPlayButton position="center" />
                    <LoadingSpinner />
                    <ControlBar disabled></ControlBar>
                  </Player>
                {/* {showVideo ? (
                  <Player
                    playsInline
                    poster={item.BannerImg}
                    src={item.BannerVideo}
                  >
                    <BigPlayButton position="center" />
                    <LoadingSpinner />
                    <ControlBar disabled></ControlBar>
                  </Player>
                ) : (
                  <img src={item.BannerImg} alt={item.title} />
                )} */}
              </ScrollAnimation>
              <ScrollAnimation
                className="banner-txt"
                animateIn="fadeInUp"
                animateOnce={true}
                delay={300}
              >
                <h2 className="banner-tl">{item.title}</h2>
                <h3 className="banner-subtl">{item.subtl}</h3>
                {/* <Link className="btn btn-primary">Sign Up</Link> */}
              </ScrollAnimation>
              {/* <h1>{count}</h1>
                                <button onClick={() => setCount(count + 1)}>Increment</button> */}
            </ScrollAnimation>
          );
        })}
      </Container>
    </div>
  );
}

export default Banner;
