import React, { Component } from 'react';

import Header from '../shared/header';
import Footer from '../shared/footer';
import StaticBanner from '../shared/staticbanner';
import HighlightText from '../shared/highlighttext';
// import FamilyMembers from '../shared/familymembers';
import ContactSec from '../shared/contactsec';
import FriendsSec from '../shared/friendsSec';

import data from '../data/clinicPartnersData.json';

class OurPartners extends Component{
    render(){
    document.title ="About Us - TwoDads U.K - Surrogacy Support in UK"; 
    document.getElementsByTagName("META")[2].content="Know more about how Two dads can complete their family. Know about us and our beautiful family.  ";
    document.getElementsByTagName("META")[3].content="Two Dads in UK, Surrogacy in UK, Gay Parents with Surrogacy Support, Surrogacy Support to grow your family, Daddies change too, Gay parents, Gay Couple, Two Dads, About Us, Our Family, Gay couples can have family";
    document.querySelector("link[rel='canonical']").setAttribute("href","https://www.twodadsuk.com/about");

    return(
        <div className="wrapper clinic-partner-page">
            <Header/>
            {data.bannerdata.map((item, i) => {
                    return <StaticBanner BannerCont={item} key={`banner-list-${i}`}/>
                }
            )}
            <div className='bg-shade type-01'>
                {data.highlightText.map((item,i)=>{ 
                        return <HighlightText HighlightData={item} key={`Highlight-list-${i}`} />
                    }
                )}
                <div className='partner-box-wrap'>
                    {data.familyMembers.map((item,i)=>{ 
                            return (
                                <>
                                    <div className='partner-box'>
                                        <div className='img-sec'>{item.imgUrl !== "" ? (<img src={item.imgUrl} alt=''/>) : (<img src='./resources/images/clinic-partners/no-image.jpg' alt=''/>)}</div>
                                        <div className='text-sec'>
                                            {item.title !== "" ? (<h2>{item.title}</h2>) : ('')}
                                            {item.para !== "" ? (<div dangerouslySetInnerHTML={{__html:item.para}} className='txt-cont'></div> ) : ('')}
                                            {item.Url !== "#" ? (<a href={item.Url} className="btn btn-primary btn-view more">Know More</a>) : ('')}
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    )}
                </div>
            </div>
            <FriendsSec/>
            <ContactSec/>
            <Footer/>
        </div>
    );
}
}

export default OurPartners;