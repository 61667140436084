import React, { Component } from 'react';
import { Carousel, Row, Col, Container, Modal, Button} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll'

import data from '../data/aboutData.json';

class FamilyMembers extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
        show: null
        };
    };

    handleClose() {
        this.setState({show: null});
      }
    
      handleShow(id) {
        this.setState({show: id});
      }
    
    render() {
        return(
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={600} className="color-sec mb-0">
                <Container>
                    <Carousel>
                        {data.familyMembers.map((item, i)=>{
                            return(
                            <Carousel.Item>                    
                                <Row xs={1} md={2}>
                                    <Col className="slider-img" xs={{ order: 12 }}>
                                        <img
                                        src={item.imgUrl}
                                        alt={item.author}/>
                                    </Col>
                                    <Col className="cont-sec" xs={{ order: 1 }}>
                                        {/* <h2 className="main-tl">{item.title}</h2> */}
                                        <h3>{item.author}</h3>
                                        <div dangerouslySetInnerHTML={{__html: item.para}}></div>
                                        <Button variant="primary" onClick={() => this.handleShow(item.reactId)}>Read More</Button>

                                        <Modal size="lg" centered show={this.state.show === item.reactId} onHide={this.handleClose} >
                                        <Modal.Header closeButton>
                                        </Modal.Header>
                                        <Modal.Body  dangerouslySetInnerHTML={{__html: item.fullpara}}></Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleClose}>Close</Button>
                                        </Modal.Footer>
                                        </Modal>
                                    </Col>                                    
                                </Row>
                            </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
                </Container>
            </ScrollAnimation>
        );
    }
}

export default FamilyMembers;