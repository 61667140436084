import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {Container, Row, Col} from 'react-bootstrap';
//import {Link} from 'react-router-dom';
import data from '../data/homeData.json';

function OurVison(){
    return(
        <ScrollAnimation className="section our-mission-sec" animateIn='fadeInUp' animateOnce={true} delay={300}>
            <Container>
                {
                    data.OurVisonSec.map((item, i) => {
                        return(
                            <div key={`OurMissionSec-list-${i}`}>
                                <h2 className="main-tl">{item.title}</h2>
                                <p className="spl-txt"><strong>{item.SecTextHighlight}</strong></p>
                                <p className="spl-txt">{item.SecText}</p>
                            </div>
                        )
                    })
                }
            </Container>
        </ScrollAnimation>
    )
}

export default OurVison;