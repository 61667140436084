import React, { Component } from 'react';
import data from '../data/contactsecdata.json'

import { Row, Col, Container } from 'react-bootstrap';

export default class AEPSec extends Component {
    render(){
        return(
            <>
            <div className="section">
                {data.contacts.map((item)=>{
                        return(
                            <>
                                <Container>
                                    <Row className="text-center">
                                        <Col>
                                            <div className="infoBox">
                                                <h2 className="sub-tl">{item.addressTL}</h2>
                                                <p>{item.address}</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="infoBox">
                                                <h2 className="sub-tl">{item.phoneTL}</h2>
                                                <p>{item.phone}</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="infoBox">
                                                <h2 className="sub-tl">{item.emailTL}</h2>
                                                <p><a href={`mailto:+ ${item.email}`}>{item.email}</a></p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                        )
                    })
                }
            </div>
            </>
        )
    }
}