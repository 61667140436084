import React from 'react';
import {Container} from 'react-bootstrap'
import Header from '../shared/header';
import Footer from '../shared/footer';
import StaticBanner from '../shared/staticbanner';

import data from '../data/tncData.json';

function TermsandConditions(){
    document.title ="Terms & Conditions"; 
    document.getElementsByTagName("META")[2].content="";
    document.getElementsByTagName("META")[3].content="";
    document.querySelector("link[rel='canonical']").setAttribute("href","https://www.twodadsuk.com/terms-conditions");
    
    return(
        <div className="wrapper">
            <Header/>
            {data.BannerData.map((item, i)=>{
                    return <StaticBanner BannerCont={item} key={`BannerData-list-${i}`}/>
                })
            }
            <div className="section tncdata">
                <Container>
                {data.tncSec.map((item, i)=>{
                        return (
                            <>
                                <div dangerouslySetInnerHTML={{__html:item.para}}></div>
                                {item.tncSecList.map((list, i)=>{
                                        return (
                                            <>
                                                <h4>{list.title}</h4>
                                                <div dangerouslySetInnerHTML={{__html:item.para}}></div>                                                
                                            </>
                                        )
                                    })
                                }
                            </>
                        )
                    })
                }
                </Container>
            </div>
            <Footer/>
        </div>
    );
}

export default TermsandConditions;