import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
// import {Container,Row, Col} from 'react-bootstrap';
// import Carousel from 'react-elastic-carousel';
// import data from '../data/homeData';

// const breakPoints = [
//     { width: 1, itemsToShow: 3, itemsToScroll: 1 },
//     { width: 375, itemsToShow: 4, itemsToScroll: 1 },
//     { width: 850, itemsToShow: 3 }
// ]

function FriendsSec(){
    return(
        <ScrollAnimation className="section friend-sec " animateIn='fadeInUp' animateOnce={true} delay={500}>
                        {/* <Container>
                            <Row className="row-grid">
        {
            data.FriendTwoDadsSec.map((item, i)=>{
                return(
                            <Col lg={6} key={`FriendTwoDadsSec-list-${i}`}>
                                <h2 className="sub-tl" animateIn='fadeInUp' animateOnce={true} delay={600}>{item.title}</h2>
                                <div className="icon-list-wrap" animateIn='fadeInUp' animateOnce={true} delay={700}>
                                    <div className="icon-list">
                                        <Carousel breakPoints={breakPoints}>
                                            {
                                                item.friendList.map((List, i=500)=>{
                                                    return(
                                                        <div key={`friendList-list-${i}`}>
                                                            <ScrollAnimation className="cara-item" animateIn='fadeInUp' animateOnce={true}><a href={List.ImgUrl} target="_blank" rel="noopener noreferrer"><img src={List.imgPath} key={`friendList-${i}`} alt=""/></a></ScrollAnimation>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Carousel>
                                    </div>
                                </div>
                            </Col>
                )
            })
        }
                                {
            data.FertilityFriendTwoDadsSec.map((item, i)=>{
                return(
                            <Col lg={6} key={`FriendTwoDadsSec-list-${i}`}>
                                <h2 className="sub-tl" animateIn='fadeInUp' animateOnce={true} delay={600}>{item.title}</h2>
                                <div className="icon-list-wrap" animateIn='fadeInUp' animateOnce={true} delay={700}>
                                    <div className="icon-list">
                                        <Carousel breakPoints={breakPoints}>
                                            {
                                                item.friendList.map((List, i=500)=>{
                                                    return(
                                                        <div key={`friendList-list-1-${i}`}>
                                                            <ScrollAnimation className="cara-item" animateIn='fadeInUp' animateOnce={true}><a href={List.ImgUrl} target="_blank" rel="noopener noreferrer"><img src={List.imgPath} key={`ffriendList-${i}`} alt=""/></a></ScrollAnimation>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Carousel>
                                    </div>
                                </div>
                            </Col>
                )
            })
        }
                            </Row>
                        </Container> */}
        </ScrollAnimation>
    )
}

export default FriendsSec;