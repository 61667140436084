import React, { Component } from 'react';
import data from '../data/contactsecdata.json';

export default class AddressSec extends Component {
    render(){
        return(
            <>
                {data.contacts.map((item)=> {
                    return(
                        <>
                            <address>{item.address}</address>
                        </>
                    )
                })
                }
            </>
        )
    }
}