import React, { Component } from 'react';
import HighlightText from '../shared/highlighttext';
import YouTube from 'react-youtube';
import ScrollAnimation from 'react-animate-on-scroll';

import data from '../data/ourweddingStoryData.json';
import { Container } from 'react-bootstrap';

class OurWeddingStory extends Component{
    render(){
        const opts = {
            height: '100%',
            width: "100%",
            playerVars: {
              autoplay: 1,
            },
          };
          
        return(
            <>
            {data.owsText.map((item,i)=>{
                return (
                <div className="section ows-str-sec" key={`section-list-${i}`}>
                    <Container>
                        <HighlightText HighlightData={item} key={`OurWeddingStory-list-${i}`} />
                        <ScrollAnimation animateIn='fadeInUp' className="video-container" animateOnce={true} delay={200}>
                            <YouTube videoId="f7TnTW5jERM" opts={opts} onReady={this._onReady} />
                        </ScrollAnimation>
                    </Container>
                </div>
                )
            })}
            </>
        );
    }
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
        //console.log("Hello We can GO Now.");
    }
}

export default OurWeddingStory;