import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import { Container,Row, Col } from 'react-bootstrap';
import ReactWhatsapp from 'react-whatsapp';

import FormSec from '../shared/formsec';
//import EmailSec from './email';
import AddressSec from './address';

import data from '../data/contactsecdata.json';

function ContactSec(){
    return(
        <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={300} className="section contactsec">
            <Container>

            
                {/* <div className="modernshowimg">
                <a href="https://themodernfamilyshow.co.uk/" target="_blank" rel="noopener noreferrer" className="d-block"><img src="../resources/images/MFS-IVF-Babble-digital.jpg" alt=""/></a>
                </div>
             */}

            {
               data.contacts.map((item,i)=>{
                   return(
                        <Row xs={1} md={2} className="contactsecIn no-gutter" key={`contacts-${i}`}>
                            <Col>
                                <ScrollAnimation className="MapImg" animateIn='fadeInUp' animateOnce={true} delay={300}>
                                    {/* <img src={item.mapImg} alt=""/> */}
                                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2431.557880254921!2d-2.0547581841951086!3d52.450923279801266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487096eadb3aa115%3A0xde6e93b30544b2a4!2sNicklin%20Accountants!5e0!3m2!1sen!2sin!4v1602190293511!5m2!1sen!2sin" width="100%" height="100%" frameborder="0" title={item.title} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.9969031926944!2d-0.14981272306958804!3d51.513272810273726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487605126cf92ec3%3A0x681606b8a8876a95!2sInternational%20House!5e0!3m2!1sen!2sin!4v1694019982262!5m2!1sen!2sin" width="100%" height="100%" frameborder="0" title={item.title} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                                </ScrollAnimation>
                            </Col>
                            <Col>
                                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={300} className="formSec">
                                    <h2 className="main-tl">{item.title}</h2>
                                    <ReactWhatsapp number="+447737115437" message="Hi Mike, I would like to know more about Surrogacy and TwoDads UK." className="btn btn-primary btn-chat"><i className="fa fa-whatsapp"></i> Chat With Us On WhatsApp</ReactWhatsapp>
                                    <AddressSec/>
                                    <p><a href={`mailto:+ ${item.email}`}>{item.email}</a></p>
                                    <FormSec/>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                   )
               }) 
            }
            </Container>
        </ScrollAnimation>
    )
}

export default ContactSec;