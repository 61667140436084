import React, {Component} from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import {Container} from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

import BlogPostFilter from '../shared/blogfilter';

import data from '../data/blogsecdata.json';

class BlogSec extends Component{
    constructor(props){
        super(props);   
        this.state = {
            Posts:[],
            isLoaded:false
        }
    }

    componentDidMount(){
        //fetch('https://twodadblog.brandastronauts.com/blog/wp-json/wp/v2/posts')
        fetch('https://twodadsuk.com/blog/wp-json/wp/v2/posts')
        .then(result => result.json())
        .then( json => {
            this.setState({
                Posts:json,
                isLoaded:true
            })
        })
    }
    
render(){
  const {isLoaded, Posts}=this.state;
  
  // get unique category items
  const uniqueItems = (x, i, array) => array.indexOf(x) === i;
  const BLOGPOST_CATEGORIES = Posts.map(proj => proj.category).filter(
      uniqueItems
  );
  BLOGPOST_CATEGORIES.sort().unshift("all");
  //console.log(BLOGPOST_CATEGORIES);

  const BLOGPOST_IMGCLASS = Posts.map(item => item.bigImage)

  //console.log(BLOGPOST_IMGCLASS);

//   setTimeout(function(){
//     console.log('setTimeout is working')
//     document.querySelectorAll('.Yes').forEach(function(button) {
//         console.log('Hello')
//     });
//   },800)
 

  if(!isLoaded){
      return(<p style={{ textAlign: 'center', fontSize: '2em' }}>Hold on, fetching data may take some time :)</p>);
  }else{
    return(
        <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={300} className="section blogsec">
            <Container>
            {data.blogPost.map((item,i)=>{
                   return(
                        <div key={`blogPost-${i}`}>
                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={300}><h2 className="main-tl">{ ReactHtmlParser(item.title) }</h2></ScrollAnimation>
                        </div>
                   )
               }) 
            }

             <BlogPostFilter blogposts={this.state.Posts} blogpostCategories={BLOGPOST_CATEGORIES} blogpostImgClass={BLOGPOST_IMGCLASS}/>
            </Container>
        </ScrollAnimation>
        )                                                                                                                                                                                                                             
    }
}
}

export default BlogSec;