import React, { Component } from 'react';

import Header from '../shared/header';
import Footer from '../shared/footer';
import StaticBanner from '../shared/staticbanner';
import HighlightText from '../shared/highlighttext';
import ContactSec from '../shared/contactsec';
import FriendsSec from '../shared/friendsSec';

import data from '../data/bookAConsultant.json';

class BookConsultation extends Component{
    render(){
    document.title ="About Us - TwoDads U.K - Surrogacy Support in UK"; 
    document.getElementsByTagName("META")[2].content="Know more about how Two dads can complete their family. Know about us and our beautiful family.  ";
    document.getElementsByTagName("META")[3].content="Two Dads in UK, Surrogacy in UK, Gay Parents with Surrogacy Support, Surrogacy Support to grow your family, Daddies change too, Gay parents, Gay Couple, Two Dads, About Us, Our Family, Gay couples can have family";
    document.querySelector("link[rel='canonical']").setAttribute("href","https://www.twodadsuk.com/about");

    return(
        <div className="wrapper">
            <Header/>
            {data.bannerdata.map((item, i) => {
                    return <StaticBanner BannerCont={item} key={`banner-list-${i}`}/>
                }
            )}

            {data.highlightText.map((item,i)=>{ 
                    return <HighlightText HighlightData={item} key={`Highlight-list-${i}`} />
                }
            )}
            
            <div className='bookConsultation-sec'>
                <iframe allowfullscreen id="bookConsultation-iframe" src="https://meetings-eu1.hubspot.com/michael-johnson-ellis?embed=true" loading="lazy" title="My social wall"></iframe> 
            </div>

            
            <FriendsSec/>
            <ContactSec/>
            <Footer/>
        </div>
    );
}
}

export default BookConsultation;