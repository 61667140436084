import React from 'react';
import data from '../data/followsecdata.json';
import {Container, Row, Col} from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
// import { FacebookProvider, EmbeddedPost } from'react-facebook';
// import React, { Component} from 'react';
import { FacebookProvider, Page } from 'react-facebook';
import { Timeline } from 'react-twitter-widgets';
// import InstagramEmbed from 'react-instagram-embed';
// import { Facebook } from 'reactjs-social-embed';
// import { Instagram } from 'reactjs-social-embed';
// import Feed from "react-instagram-authless-feed";

// export default class Example extends Component {
//     render() {
//       return (
//         <FacebookProvider appId="123456789">
//           <Page href="https://www.facebook.com" tabs="timeline" />
//         </FacebookProvider>    
//       );
//     }
//   }


function FollowSec(){
    return(
        <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={300} className="section follow-sec">
            <Container>
                {
                    data.followList.map((item,i)=>{
                        return(
                            <div key={`followList-${i}`}>
                                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={400}><h2 className="main-tl">{item.title}</h2></ScrollAnimation>
                                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} delay={500} className="spl-txt"><p>{item.para}</p></ScrollAnimation>
                                <Row className="boxlist">
                                    {/* {
                                        item.followListData.map((list, i)=>{
                                            return(
                                                <Col key={`followListData-${i}`}><ScrollAnimation className="box shadowBox" animateIn='fadeInUp' animateOnce={true} delay={600}><img src={list} alt=""/></ScrollAnimation></Col>
                                            )
                                        })
                                    } */}
                                    <Col>
                                        <div className="box shadowBox facebookbox">
                                        <FacebookProvider appId="929115474288556" height="100%" width="100%" adaptContainerWidth="true">
                                            <Page href="https://www.facebook.com/twodads.u.k/" tabs="timeline" />
                                        </FacebookProvider>   
                                        </div>
                                    </Col>
                                    {/* <Col>
                                        <div className="box shadowBox InstaGrambox">
                                            <div class="timeline-Header timeline-InformationCircle-widgetParent" data-scribe="section:header">
                                            </div>
                                            <h1 class="timeline-Header-title u-inlineBlock" data-scribe="element:title">Instagram Posts <span class="timeline-Header-byline" data-scribe="element:byline">by 
                                            <a class="customisable-highlight" href="https://www.instagram.com/twodads.u.k" title="&lrm;@TwoDads_UK on Twitter">&lrm;@TwoDads_UK</a></span></h1>
                                        </div>
                                    </Col> */}
                                    {/* <Col>
                                        <FacebookProvider appId="359809265414419">
                                            <EmbeddedPost href="https://www.facebook.com" width="100%" >
                                            <div class="fb-post" data-href="https://www.facebook.com/20531316728/posts/10154009990506729/" data-show-text="true" data-width=""><blockquote cite="https://www.facebook.com/20531316728/posts/10154009990506729/" class="fb-xfbml-parse-ignore">Posted by <a href="https://www.facebook.com/facebookapp/">Facebook App</a> on&nbsp;<a href="https://www.facebook.com/20531316728/posts/10154009990506729/">Thursday, August 27, 2015</a></blockquote></div>
                                            </EmbeddedPost>
                                        </FacebookProvider>
                                    </Col> */}
                                    {/* <Col>
                                        <div className="box shadowBox tweetbox"> 
                                            <Twitter id="1123643561772187649" />
                                        </div>      
                                    </Col> */}
                                    {/* <Col>
                                        <div className="box shadowBox tweetbox">                        
                                            <Timeline
                                                dataSource={{
                                                    sourceType: 'profile',
                                                    screenName: 'TwoDads_UK',
                                                    noHeader:true,
                                                    noFooter:true
                                                }}
                                                options={{
                                                    height: '100%'
                                                }}
                                            />
                                        </div>      
                                    </Col> */}
                                </Row>
                            </div>
                        )
                    })
                }
            </Container>
        </ScrollAnimation>
    )

}

export default FollowSec;