import React from 'react';
import Header from '../shared/header';
import Footer from '../shared/footer';
import StaticBanner from '../shared/staticbanner';
import {Row, Col} from 'react-bootstrap'

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

import data from '../data/gallertData'

// const images = [
//     {
//       src: 'https://www.simple-react-lightbox.dev/docs/gallery/unsplash18.jpg',
//       thumbnail:
//         'https://www.simple-react-lightbox.dev/docs/gallery/thumbnails/unsplash18.jpg',
//       caption: 'Lorem ipsum dolor sit amet',
//       width: 1920,
//       height: 'auto'
//     },
//     {
//       src: 'https://www.simple-react-lightbox.dev/docs/gallery/unsplash19.jpg',
//       thumbnail:
//         'https://www.simple-react-lightbox.dev/docs/gallery/thumbnails/unsplash19.jpg',
//       caption: 'Consecutur adiscip elit',
//       width: 2000,
//       height: 'auto'
//     },
//   ]


function Gallery(){
    document.title ="Gallery - TwoDads U.K - Surrogacy Support in UK"; 
    document.getElementsByTagName("META")[2].content="Best Snaps cannot be forgotten, They are the best preserved memories. Find our Best Snaps here.";
    document.getElementsByTagName("META")[3].content="Two Dads in UK, Surrogacy in UK, Gay Parents with Surrogacy Support, Surrogacy Support to grow your family, Daddies change too, Gay parents, Gay Couple, Two Dads, About Us, Our Family, Gay couples can have family, Precious moments";
    document.querySelector("link[rel='canonical']").setAttribute("href","https://www.twodadsuk.com/gallery");
    

    return(
        <>
            <div className="wrapper">
                <Header/>
                {data.bannerdata.map((item, i)=>{
                        return <StaticBanner BannerCont={item} key={`Blogdata-${i}`}/>
                    })
                }

                <div className="section">
                    <div className="container">
                        <SimpleReactLightbox>
                            <SRLWrapper>
                                <Row className="no-gutters">
                                {
                                    data.galleryData.map((item, i)=>{
                                        return(
                                            <Col xs="6" sm="3">
                                                <a className="img-thumbnail" href={item.src} data-attribute="SRL">
                                                    <img src={item.thumbnail} alt={item.caption} />
                                                </a>
                                            </Col>
                                        )
                                    })
                                }
                                </Row>
                            </SRLWrapper>
                        </SimpleReactLightbox>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default Gallery;