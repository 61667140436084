import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import SocialIcons from '../shared/social-icons';
import BackToTop from 'react-back-to-top-button';
import NewsLetter from '../shared/newsletter';



// var loadfirst = localStorage.getItem("loadfirsttime");
// if(loadfirst == null){
//     localStorage.setItem('loadfirst ', 1); // 1 is value assigned.
//     // function PopUp(hideOrshow) {
//     //     if (hideOrshow === 'hide') document.getElementById('ac-wrapper').classList.add("d-none");
//     //     else document.getElementById('ac-wrapper').classList.remove("d-none");
//     // }
//     //PopUp('show');
// }

function Footer(){
    return(
        <footer>
            <Container>
                <Row xs={1} sm={2} className="text-xs-center">
                    <Col>
                        <Row>
                            <Col xs={12} sm={4} >
                                <Link to="/" className="footer-logo"><img src="../Logo@4x.png" alt=""/></Link><br/>
                                <div className='log-wrap'>
                                    <span className="footer-logo"><img src="../resources/images/TDUK-rainbow-awards-roundall.png" alt=""/></span>
                                    <span className="footer-logo"><img src="../resources/images/common/Top-20-badge-2023.png" alt=""/></span>
                                </div>
                            </Col>
                            <Col xs={12} sm={4}>
                                <ul className="footerNav">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/video-library">Video Library</Link></li>
                                    <li><Link to="/our-journey">Our Journey</Link></li>
                                    <li><Link to="/media-projects">Media Projects</Link></li>
                                    <li><Link to="/clinic-partners">Our Partners</Link></li>
                                    {/* <li><Link to="/features">Features</Link></li>
                                    <li><Link to="/pricing">Pricing</Link></li> */}
                                </ul>
                            </Col>
                            <Col xs={12} sm={4}>
                                <ul className="footerNav">
                                    <li><a href="https://twodadsuk.com/blog/">Blog</a></li>
                                    {/* <li><Link to="/events">Events</Link></li> */}
                                    <li><Link to="/gallery">Gallery</Link></li>
                                    <li><Link to="/book-a-consultation">Book a Consultation</Link></li>
                                    {/* <li><Link to="/search">Search</Link></li>*/}
                                    <li><Link to="/terms-conditions">T&amp;Cs</Link></li>
                                    <li><Link to="/privacy-policy">Privacy policy</Link></li>
                                    {/*<li><Link to="/community">Community</Link></li> */}
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="">
                        <div className="NewsletterSec ml-sm-auto">
                            <h4>Get Our Newsletter</h4>
                            {/* <div class="input-group mb-3">
                                <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon2"/>
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="button">Subscribe</button>
                                </div>
                            </div> */}
                            <NewsLetter/>
                            <SocialIcons/>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="footer_copyright">
                <div class="container">
                    <div className="copyright_text text-center">
                        <p>&copy; Copyright - <a target="_blank" rel="noopener noreferrer" href="https://twodadsuk.com/">twodadsuk.com</a>.</p>
                        <p>Designed &amp; Developed by <a target="_blank" rel="noopener noreferrer" href="https://brandastronauts.com/">Brand Astronauts</a></p>	
                    </div>
                </div>
            </div>
            <BackToTop
                showOnScrollUp
                showAt={100}
                speed={1500}
                easing="easeInOutQuint"
            >
                <i className="fa fa-chevron-up"></i>
            </BackToTop>
            {/* <div id="ac-wrapper" className="d-none">
                <div>
                    <Link className="btn-close" onClick="PopUp('hide')">x</Link>
                    <Link href="https://themodernfamilyshow.co.uk/" ><img src="../resources/images/the-modern-family-show.jpg" alt=""/></Link>
                    <input type="submit" name="submit" value="Submit" onClick="PopUp('hide')" />
                </div>
            </div>  */}

        </footer>
    );
}

export default Footer;