import React from 'react';

import Header from '../shared/header';
import Footer from '../shared/footer';
import StaticBanner from '../shared/staticbanner';

import MediaProjectsVid from '../shared/MediaProjectsvid';

import data from '../data/MediaProjectsData.json';

function MediaProjects(){
    document.title ="Media projects - TwoDads U.K - Surrogacy Support in UK"; 
    document.getElementsByTagName("META")[2].content="The Media projects we have worked on comes under this page.";
    document.getElementsByTagName("META")[3].content="Two Dads in UK, Surrogacy in UK, Gay Parents with Surrogacy Support, Surrogacy Support to grow your family, Daddies change too, Gay parents, Gay Couple, Two Dads, Media Projects, Media Connect";
    document.querySelector("link[rel='canonical']").setAttribute("href","https://www.twodadsuk.com/media-projects");
    
    return(
        <div className="wrapper">
            <Header/>
            {data.BannerData.map((item, i)=>{
                    return <StaticBanner BannerCont={item} key={`BannerData-list-${i}`}/>
                })
            }
            <MediaProjectsVid/>
            <Footer/>
        </div>
    );
}

export default MediaProjects;